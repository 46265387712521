<template>

    <v-app>

        <v-navigation-drawer app v-model="drawer" class="v-gradient" width="200">
            <v-flex text-center>
                <!--<v-img src="myImage" class="mx-auto mt-7" width="140"></v-img>-->
                <v-img src="../public/ose-logo.png" class="mx-auto mt-7" width="140"></v-img>
                <!--<img :src="require('../public/ose-logo.png')" class="mx-auto mt-7" width="140">-->
            </v-flex>
        </v-navigation-drawer>

        <v-app-bar app light class="h-gradient">
            <v-app-bar-nav-icon @click.stop="drawer=!drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>Meter Readings</v-toolbar-title>
        </v-app-bar>

        <v-content class="mb-16 mb-sm-0">

            <v-container fluid>

                <v-stepper alt-labels
                           v-model="stepper.step"
                           class="shadow--hidden theme--transparent">

                    <v-stepper-header class="shadow--hidden">
                        <v-card class="d-flex justify-space-between align-stretch width--full flex-wrap" outlined>
                            <template v-for="(s, index) in steps">
                                <v-stepper-step :key="`${index + 1}-step`"
                                        :complete="stepper.step > (index + 1)"
                                        :step="`${index + 1}`">
                                    {{s.name}}
                                </v-stepper-step>
                                <v-divider v-if="index !== steps.length - 1" :key="`${index + 1}-hr`"></v-divider>
                            </template>
                        </v-card>
                    </v-stepper-header>

                    <v-spacer class="pa-2"></v-spacer>

                    <v-stepper-items>

                        <v-stepper-content step="1" class="pa-0">
                            <meter-search :model="model" :stepper="stepper"></meter-search>
                        </v-stepper-content>

                        <v-stepper-content step="2" class="pa-0">
                            <meter-list :model="model" :stepper="stepper"></meter-list>
                        </v-stepper-content>

                        <v-stepper-content step="3" class="pa-0">
                            <meter-reading :model="model" :stepper="stepper"></meter-reading>
                        </v-stepper-content>

                        <v-stepper-content step="4" class="pa-0">
                            <submit-reading :model="model" :stepper="stepper"></submit-reading>
                        </v-stepper-content>

                        <v-stepper-content step="5" class="pa-0">
                            <success-submit :model="model" :stepper="stepper"></success-submit>
                        </v-stepper-content>

                    </v-stepper-items>

                </v-stepper>

            </v-container>

        </v-content>

    </v-app>

</template>

<script>

    import Vue from 'vue';
    import MeterSearch from './components/MeterSearch';
    import MeterList from './components/MeterList';
    import MeterReading from './components/MeterReading';
    import SubmitReading from './components/SubmitReading';
    import SuccessSubmit from './components/SuccessSubmit';

    Vue.filter('emptyFormatter', value => value ? value : '- - -');
    Vue.filter('noValue', value => value ? value : '< No Value >');
    Vue.filter('noMake', value => value ? value : '<Unkown Make>');
    export default {

        name: 'App',

        components: {
            MeterSearch,
            MeterList,
            MeterReading,
            SubmitReading,
            SuccessSubmit
        },

        data: () => ({
            drawer: null,

            stepper: {
                step: 1,
                validators: []
            },
            steps: [
                {name: "Search"},
                {name: "Select Meter"},
                {name: "Enter Readings"},
                {name: "Submit"}
            ],

            model: {
                search: {
                    well_tag_nbr: '',
                    basin: '',
                    wr_nbr: '',
                    suffix: '',
                    filter_by: 'file'
                },
                pods: {
                    list: [],
                    selected: {}
                },
                meter: {
                    selected: {
                        current_reading_date: new Date(),
                        number_of_dials: 0,
                        readings: []
                    }
                },
                submitter: {
                    contact_email: '',
                    contact_phone: '',
                    agree_terms: '',
                    submitted_by: '',
                    dist_office: '',
                },
                submission: {
                    submission_id: '',
                    submitted_date: ''
                },
            },
        }),
        methods: {
            updateStep(newStepper){
                this.stepper = newStepper
            },
            updateModel(newModel){
                this.model = newModel
            }
        }
    };

</script>
